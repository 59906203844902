/*************************************************
 * Filename: Layout.module.css
 * 
 * Purpose: Provides styles for the main layout component of the portfolio website.
 * This includes the two-panel design, animations, responsive behavior, and
 * theming for all interactive elements.
 * 
 * Project Role:
 * - Defines the core layout structure
 * - Handles responsive design
 * - Manages animations and transitions
 * - Sets color scheme and typography
 *************************************************/

/* Main Layout Structure
-------------------------------------------------- */
.container {
  display: flex;
  min-height: 100vh;
  background-color: #1a1a2e;
  color: #ffffff;
}

.leftPanel {
  width: 30%;
  padding: 2rem;
  position: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.rightPanel {
  width: 70%;
  margin-left: 30%;
  padding: 2rem;
  overflow-y: auto;
  min-height: 100vh;
  box-sizing: border-box;
}

/* Animations
-------------------------------------------------- */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes rainbow {
  0% { color: red; }
  16.67% { color: orange; }
  33.33% { color: yellow; }
  50% { color: green; }
  66.67% { color: blue; }
  83.33% { color: indigo; }
  100% { color: violet; }
}

/* Introduction Elements
-------------------------------------------------- */
.introduction {
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
}

.greeting {
  font-family: 'Quicksand', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin-bottom: 0.5rem;
  color: #7a7a7a;
}

/* Name styling with rainbow animation */
.name {
  font-family: 'Quicksand', sans-serif;
  font-size: 2.3rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  opacity: 0;
  animation:
          fadeIn 0.5s ease-out 0.35s forwards,
          rainbow 4s linear 0.5s infinite;
}

.nameLink {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nameLink:hover {
  color: #0f3460;
}

/* Content Sections
-------------------------------------------------- */
.content {
  opacity: 0;
  animation: fadeIn 1s ease-out 0.75s forwards;
}

.title {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #ebeffb;
}

/* Navigation
-------------------------------------------------- */
.navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.navigation a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.navigation a:hover {
  color: #0f3460;
}

/* Social Icons
-------------------------------------------------- */
.socialIcons {
  display: flex;
  gap: 1rem;
}

.socialIcons a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.socialIcons a:hover {
  color: #0f3460;
  transform: scale(1.1);
}

/* Email Display
-------------------------------------------------- */
.emailIcon {
  font-size: 1.5rem;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.emailIcon:hover {
  transform: scale(1.1);
}

.emailDisplay {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #a0a0a0;
  transition: opacity 0.3s ease;
}

/* Content Cards
-------------------------------------------------- */
.aboutSection,
.projectsSection,
.experienceSection {
  margin-bottom: 3rem;
}

.projectCard,
.experienceItem {
  background-color: #16213e;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.projectCard:hover,
.experienceItem:hover {
  transform: translateY(-5px);
}

/* Typography
-------------------------------------------------- */
h2 {
  color: #0f3460;
  margin-bottom: 1rem;
}

/* Responsive Design
-------------------------------------------------- */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .leftPanel,
  .rightPanel {
    width: 100%;
    position: static;
    height: auto;
    margin-left: 0;
  }
}

/* Alternate color scheme (commented for reference)
-------------------------------------------------- 
@keyframes darkVioletScheme {
    0% { color: #F5E6FF; }   
    16.67% { color: #DABFFF; } 
    33.33% { color: #B588FF; } 
    50% { color: #9A55FF; }    
    66.67% { color: #7B33FF; } 
    83.33% { color: #6110D4; } 
    100% { color: #450089; }   
}
*/