/*************************************************
 * Filename: index.css
 *
 * Purpose: Provides base styling for HTML elements and sets up
 * font families and text rendering options.
 *
 * Project Role:
 * - Defines default font stacks for the application
 * - Sets up text anti-aliasing
 * - Provides basic reset styles
 *************************************************/

/* Base body styles with system font stack */
body {
    margin: 0;
    /* Progressive font stack starting with system fonts */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    /* Improve text rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Monospace font stack for code elements */
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
