/*************************************************
 * Filename: projects_styles.css
 * 
 * Purpose: Provides styling for the projects page, with a focus on 
 * link buttons and their interactions. Creates distinct visual styles
 * for different types of links (live demo, GitHub, YouTube).
 * 
 * Project Role:
 * - Defines link button appearances
 * - Manages hover interactions
 * - Handles responsive layout
 * - Creates consistent button styling
 * 
 * Connected Files:
 * - projects_page.js: The component this styles
 * - Layout.module.css: Parent styles this builds upon
 *************************************************/

/* Link Container Styling
-------------------------------------------------- */
/* Container for project links with flexible layout */
.project_links {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem; /* Consistent spacing between links */
}

/* Base Link Button Styling
-------------------------------------------------- */
/* Common styles for all project links */
.project_link {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #4a90e2; /* Default blue for live demo links */
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease; /* Smooth hover transition */
}

/* Hover state for default links */
.project_link:hover {
  background-color: #3a7bc8; /* Darker blue on hover */
}

/* GitHub Link Styling
-------------------------------------------------- */
/* Dark theme for GitHub links */
.github_link {
  background-color: #333; /* GitHub's signature dark color */
}

.github_link:hover {
  background-color: #555; /* Lighter gray on hover */
}

/* YouTube Link Styling
-------------------------------------------------- */
/* Red theme for YouTube links */
.youtube_link {
  background-color: #832424; /* Dark red base color */
}

.youtube_link:hover {
  background-color: #CC0000; /* YouTube's signature red on hover */
}

/* Responsive Design
-------------------------------------------------- */
/* Mobile-specific adjustments */
@media (max-width: 480px) {
  /* Stack links vertically on small screens */
  .project_links {
    flex-direction: column;
  }

  /* Full-width buttons on mobile */
  .project_link {
    width: 100%;
    text-align: center;
  }
}


/* Project Status Styling */
.project_status {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
}

.in_progress {
  background-color: #4a90e2;
  color: white;
}

.in_development {
  background-color: #50c878;
  color: white;
}