/*************************************************
 * Filename: home_styles.module.css
 * 
 * Purpose: Provides styling for the home page component, including layout,
 * animations, responsive design, and image handling. This module creates
 * a professional and engaging presentation of portfolio content.
 * 
 * Project Role:
 * - Defines the main layout structure
 * - Manages responsive design
 * - Handles image styling and scaling
 * - Creates interactive animations
 * 
 * Connected Files:
 * - home_page.js: The main component this styles
 * - Layout.module.css: Parent styles this builds upon
 *************************************************/

/* Main Layout
-------------------------------------------------- */
/* Container for all content */
.rightPanel {
  padding: 2rem;
}

/* Introduction Section Layout
-------------------------------------------------- */
.introSection {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

/* Profile image styling */
.headshot {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 2rem;
}

/* Introduction text list styling */
.introText ul {
  list-style-type: none;
  padding: 0;
}

.introText li {
  margin-bottom: 0.5rem;
}

/* Introduction links styling */
.introText a {
  color: #4a90e2;
  text-decoration: none;
  transition: color 0.3s ease;
}

.introText a:hover {
  color: #3a7bc8;
  text-decoration: underline;
}

/* Section Spacing
-------------------------------------------------- */
.aboutSection,
.projectsSection,
.certificationsSection {
  margin-bottom: 3rem;
}

/* Projects Layout
-------------------------------------------------- */
/* Container for project cards */
.projectsContainer {
  display: flex;
  justify-content: space-between;
}

/* Individual project card styling */
.projectCard {
  background-color: #16213e;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  transition: transform 0.3s ease;
  width: 48%;
  display: flex;
  flex-direction: column;
}

.projectCard:hover {
  transform: translateY(-5px);
}

/* Project content styling */
.projectCard h3 {
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.projectCard p {
  flex-grow: 1;
}

/* Project link styling */
.projectLink {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #4a90e2;
  text-decoration: none;
}

.projectLink:hover {
  text-decoration: underline;
}

/* Project image styling */
.projectImage {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 1rem;
  object-fit: cover;
}

/* Certifications Layout
-------------------------------------------------- */
.certificationCard {
  background-color: #16213e;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.certificationCard:hover {
  transform: translateY(-5px);
}

/* Certification content styling */
.certificationCard h3 {
  color: #ffffff;
  margin-bottom: 1rem;
  text-align: center;
}

.certificationCard p {
  color: #e0e0e0;
  margin-top: 1rem;
  text-align: center;
}

/* Certification image styling */
.certificationImage {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
}

/* Organization Logos
-------------------------------------------------- */
.logosSection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  gap: 2rem;
}

/* Logo sizing */
.logo {
  width: 150px;
  height: auto;
  object-fit: contain;
}

.logovt {
  width: 300px;
  height: auto;
  object-fit: contain;
}

/* Typography
-------------------------------------------------- */
h2 {
  color: #0f3460;
  margin-bottom: 1rem;
}

h3 {
  color: #ffffff;
  margin-bottom: 0.5rem;
}

/* Responsive Design
-------------------------------------------------- */
@media (max-width: 768px) {
  /* Stack introduction on mobile */
  .introSection {
    flex-direction: column;
    text-align: center;
  }

  .headshot {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  /* Full-width projects on mobile */
  .projectsContainer {
    flex-direction: column;
  }

  .projectCard {
    width: 100%;
  }

  /* Fixed height project images on mobile */
  .projectImage {
    height: 200px;
  }

  /* Full-width certifications on mobile */
  .certificationCard {
    width: 100%;
  }

  .certificationImage {
    max-width: 100%;
  }

  /* Stack logos on mobile */
  .logosSection {
    flex-direction: column;
    gap: 1rem;
  }

  .logo {
    width: 80px;
  }
}