/*************************************************
 * Filename: AboutPage.module.css
 * 
 * Purpose: Provides styling for the About page component, including animations,
 * responsive layouts, image handling, and scroll-based reveals. This module
 * creates a modern, interactive presentation of personal and professional info.
 * 
 * Project Role:
 * - Defines animations for section reveals
 * - Handles responsive image layouts
 * - Creates consistent section styling
 * - Manages typography and colors
 * 
 * Connected Files:
 * - about_page.js: The main component this styles
 * - Layout.module.css: Parent styles this builds upon
 *************************************************/

/* Container Styles
-------------------------------------------------- */
/* Main container for the about page content */
.aboutContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 2rem;
  font-family: 'Quicksand', sans-serif;
  scroll-behavior: smooth; /* Smooth scrolling for navigation */
}

/* Section Styles and Animations
-------------------------------------------------- */
/* Base section styling with initial animation state */
.section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  margin-bottom: 2rem;
  padding: 1.5rem;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Visible state for sections after animation */
.section.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Alternating section background colors for visual variety */
.section:nth-child(3n) {
  background-color: rgba(74, 144, 226, 0.1); /* Blue tint */
}

.section:nth-child(3n+1) {
  background-color: rgba(80, 200, 120, 0.1); /* Green tint */
}

.section:nth-child(3n+2) {
  background-color: rgba(226, 74, 74, 0.1); /* Red tint */
}

/* Image Section Layouts
-------------------------------------------------- */
/* Flex container for sections with images */
.imageSection {
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

/* Standard section image styling */
.sectionImage {
  width: auto;
  height: 300px;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Image placeholder for development/missing images */
.imagePlaceholder {
  width: 200px;
  height: 150px;
  background-color: #2a2a4a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  border-radius: 8px;
  flex-shrink: 0;
  text-align: center;
  padding: 10px;
  font-size: 0.9rem;
}

/* Teaching Section Layout
-------------------------------------------------- */
.teachingSection {
  display: flex;
  flex-direction: column;
}

.teachingContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.teachingText {
  flex: 1;
  margin-right: 2rem;
}

.fullWidthImage {
  width: 60%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
  margin-top: 1rem;
}

/* Nonprofit Section Layout
-------------------------------------------------- */
.nonprofitSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nonprofitContent {
  flex: 1;
  margin-right: 2rem;
}

/* Typography
-------------------------------------------------- */
h2 {
  color: #4a90e2; /* Bright blue for main headers */
  margin-bottom: 1rem;
  font-size: 2rem;
}

h3 {
  color: #50c878; /* Green for subheaders */
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
}

p {
  line-height: 1.6;
  color: #e0e0e0; /* Light grey for readable text */
  font-size: 1rem;
}

/* Responsive Design
-------------------------------------------------- */
@media (max-width: 768px) {
  /* Stack layouts on mobile */
  .imageSection,
  .teachingContent,
  .nonprofitSection {
    flex-direction: column;
  }

  /* Full-width images on mobile */
  .sectionImage,
  .smallImage {
    width: 100%;
    height: auto;
    max-height: 300px;
    margin-top: 1rem;
  }

  /* Remove right margins on mobile */
  .teachingText,
  .nonprofitContent {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  /* Shorter cover image on mobile */
  .fullWidthImage {
    height: 100px;
  }

  /* Full-width placeholder on mobile */
  .imagePlaceholder {
    width: 100%;
    max-width: 300px;
    height: 200px;
  }
}